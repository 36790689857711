html, body, input, button {
  color: white;
  font-family: 'Open Sans', sans-serif;
  height: 100%;
  margin: 0;
}

.app {
  height: 100vh;
  background-color: #13151a;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat {
  background-color: #272b35;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: 1.5em;
  text-align: center;
  margin: 0;
  margin-top: -8px;
}

.messageBox {
  width: 500px;
  max-width: 87vw;
  height: 300px;
  overflow-y: scroll;
  background-color: #3d4351;
  margin: 10px 0;
  border-radius: 5px;
}

.messageBox::-webkit-scrollbar {
  width: 7px;
}

.messageBox::-webkit-scrollbar-track {
  background: #1f232c;
  border-radius: 5px;
}

.messageBox::-webkit-scrollbar-thumb {
  background: #acacac;
  border-radius: 5px;
}

.messageSendBox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.messageInput {
  background-color: #3d4351;
  border: none;
  border-radius: 5px;
  height: 30px;
  font-size: 1.1em;
  color: white;
  flex-grow: 1;
}

.messageInput:focus {
  outline: none;
}

.messageSendButton {
  background-color: #3d4351;
  border: none;
  border-radius: 5px;
  height: 30px;
  color: white;
  font-size: 1.1em;
  cursor: pointer;
}

.message {
  background-color: #505766;
  border-radius: 5px;
  padding: 8px;
  margin: 7px;
}

.messageAuthor {
  color: #d2d2d2;
  font-size: 0.8em;
  margin: 0;
}

.messageContent {
  margin: 0;
}
